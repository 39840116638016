import React, { Suspense } from "react"

import { isSSR } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"

const SuperDigitalLoans = React.lazy(() =>
  import("../components/SuperDigitalLoans")
)

export default () => {
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title="Super prestamos digitales"
            keywords={["Iban", "Online", "Super", "prestamos", "Digitales"]}
            description="Iban Online"
          />
          <Layout>
            <SuperDigitalLoans />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
